'use strict';

var App = {
	log(c){
		console.log(c);
	},
	isMobile(){
		return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(navigator.userAgent.toLowerCase()));
	}
};



App.SlickOfferSlider = {
	init(){

		$('.slick-offer').slick({
		//   centerMode: true,
		  dots: true,
		  arrows: false,
		  centerPadding: '60px',
		  slidesToShow: 3,
		  slidesToScroll: 3,
		  responsive: [
		    {
		      breakpoint: 768,
		      settings: {
		        arrows: false,
		        // centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 2,
				slidesToScroll: 2
		      }
		    },
		    {
		      breakpoint: 480,
		      settings: {
		        arrows: false,
		        // centerMode: true,
		        centerPadding: '40px',
		        slidesToShow: 1,
				slidesToScroll: 1
		      }
		    }
		  ]
		});
	}
};

App.MobileMenu = {
	init(){

		$(window).resize(this.onResize);

		var hamburger = $('#hamburger-icon');
		hamburger.click(function(e) {
			 hamburger.toggleClass('active');
			 $('.mobile-menu').toggleClass('active');
			 e.preventDefault();
			 e.stopPropagation();
		});

		$('body').on('click', function(event) {
			hamburger.removeClass('active');
			$('.mobile-menu').removeClass('active');
		});

		$('.mobile-menu').on('click', function(e) {
			e.stopPropagation();
		});


		var $mMenu = $('.mobile-menu  ul > li > a');
		$mMenu.on('click',function(e) {

			 $(this).parent('li').toggleClass('open');
			 console.log(this);

			 if( $(this).next('.submenu').length ){
				 e.preventDefault();
			 }

			//  e.preventDefault();
			//  e.stopPropagation();
		});


		$('.mobile-menu > ul  li > ul > a').on('click',function(e) {
			hamburger.removeClass('active');
			$('.mobile-menu').removeClass('active');
		});


	},
	onResize(){
		if( window.innerWidth > 600 ){
			$('#hamburger-icon').removeClass('active');
			$('.mobile-menu').removeClass('active');
		}
	}
};

App.MessageAlert = {
	init(){

		$("#messageIcon").on('click',this.openAlert)

	},

	openAlert(e){
		e.preventDefault();

		swal({
		//   title: 'Input something',
		  html: $('#alertMessageContent').html(),
		//   showCancelButton: true,
		//   closeOnConfirm: false,
		  allowOutsideClick: true,
		  buttonsStyling: false,
		  confirmButtonClass: 'button button-white',
		  background: "",
		  showCloseButton: true,
		  width: 600
		},
		function() {

		});
	}
};

App.LoginAlert = {
	init(){

	},
	openAlert(){
		var _this = this;
		swal({
				customClass: 'sweetalert-blue',
			  title: 'Logowanie',
			  html: '<p>By zalogować się do kąciku rodzica musisz podać hasło.</p><p><input type="text" id="pass-input-field"></p>',
			  showCancelButton: false,
			  confirmButtonText: 'Logowanie',
			  confirmButtonClass:'button button-pink round',
	  		  cancelButtonText: 'Anuluj',
	  		  cancelButtonClass: 'button button-white round',
			  closeOnConfirm: false,
			  allowEscapeKey: false,
			  buttonsStyling: false,
			  background: '#0092ff',
			  allowOutsideClick: false
		}).then(function(isConfirm) {
		  if (isConfirm) {
				//  	swal.enableLoading();
				_this.postPass();

		  }
	  });
  },
  postPass(){
	   $('.sweet-alert.sweetalert-blue').removeClass('shake');

	   function reloadHref(){
		   return String(window.location.href).replace(/#.+/,'');
	   };

	  $.post(window.location.href,{ pass:$('#pass-input-field').val() })
	  .then(function (data) {
		   swal.enableButtons();

			  if(parseInt(data)){ // success
				  swal({
						  customClass: 'sweetalert-blue',
						title:'Zostałeś zalogowany',
						html:'',
						type:'success',
						showCancelButton: false,
						confirmButtonText: 'OK',
						confirmButtonClass:'button button-pink round',
						//   cancelButtonText: 'Anuluj',
						//   cancelButtonClass: 'button button-white round',
						closeOnConfirm: false,
						allowEscapeKey: false,
						buttonsStyling: false,
						background: '#0092ff',
						allowOutsideClick: false
				  },function(){   });
				  setTimeout(function(){
					  window.location.href = reloadHref();
				  },1000);

			  }
			  else{
				  $('.sweet-cancel').removeAttr('disabled');
				  $('.sweet-alert.sweetalert-blue').addClass('shake');

				  setTimeout(function(){
					  window.location.href = reloadHref();
				  },2);
			  }

	  });
  }
};



App.Maps = {
	init: function  () {
		// This example displays a marker at the center of Australia.
		// When the user clicks the marker, an info window opens.
		var gmap = document.getElementById('gmap');

		var locations = [
			{
				title:'ul. Kasztelańska 4',
				location: new google.maps.LatLng(52.3989300,16.8707200)
			},
			{
				title:'ul. Wolsztyńska 4',
				location: new google.maps.LatLng(52.405352, 16.884416 )
			}			
		];

		if(window.google && gmap){
			// 52.3989102,16.8697761
			// var location = new google.maps.LatLng(52.3989300,16.8707200);
			// console.log(location);
			var center = locations[0].location;
			// location = center;
			var map = new google.maps.Map(document.getElementById('gmap'), {
				zoom: 15,
				center: center,
				scrollwheel: false,
				styles:[{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
			});

			var contentString = '<div id="content">'+
			  // '<div id="siteNotice">'+
			  // '</div>'+
			  // '<h1 id="firstHeading" class="firstHeading"></h1>'+
			  '<div id="bodyContent">{str}'+
			 // '<img style="display:block" src="img/wolska-map-logo.png" />' +
			  '</div>'+
			  '</div>';

			var infowindow = new google.maps.InfoWindow({
				content: contentString
			});
			var image = {
			        url:  window.BASE_URL + 'img/map-marker.png', // image is 512 x 512
			        scaledSize : new google.maps.Size(100, 100)
			    };

				for( var i=0; i<locations.length; i++){
					var place = locations[i];
					var marker = new google.maps.Marker({
						position: place.location,
						icon: image,
						map: map,
						title: place.title //,
						// animation: google.maps.Animation.BOUNCE // google.maps.Animation.DROP
					});
					place.marker = marker;
					marker.addListener('click', function() {
						infowindow.setContent( contentString.replace(/{str}/i, this.title) );
						infowindow.open(map, this.marker);
					}.bind(place));
				}

			google.maps.event.addDomListener(window, "resize", function() {
		    //  var center = map.getCenter();
		    
			 
				var latlngbounds = new google.maps.LatLngBounds();
				for (var i = 0; i < locations.length; i++) {
				latlngbounds.extend(locations[i].location);
				}
				map.fitBounds(latlngbounds);
		    });
			 
			window.dispatchEvent(new Event('resize'))
		}

	}
};

//
//
// App.Employees = {
//
// 	current:null,
//
// 	init(){
// 		var _this = this;
// 		if( $(".employee").length === 0 ) return;
//
// 		$(".employee").on('click',this.onClick.bind(this));
// 		$(window).resize(function () {
// 			_this.setPosition();
// 		});
//
// 	},
//
// 	onClick(e){
// 		e.preventDefault();
//
// 		var el = e.currentTarget;
// 		this.current = el;
//
// 		var open = !$(el).hasClass('active');
// 		if( $(el).hasClass('active') ){
// 			$(".employee").removeClass('active');
// 		}else{
// 			$(".employee").removeClass('active');
// 			$(el).addClass('active');
// 		}
//
// 		this.setPosition();
//
// 		this.toggleInfo(open);
//
//
//
// 	},
// 	toggleInfo(open){
// 		var el = this.current;
//
// 		if( open ){
// 			$('.employee-info').slideDown('fast',function(){
// 				$(this).addClass('active');
// 			});
// 		}
// 		else{
// 			$('.employee-info').slideUp('fast',function(){
// 				$(this).removeClass('active');
// 			});
// 		}
//
//
// 	},
// 	setPosition(){
// 		var el = this.current;
// 		var offsetTop = $(el).position().top;
// 		// var offsetLeft = $(el).position().left;
// 		var offsetLeft = $(el).offset().left;
// 		var elHeight = $(el).outerHeight() + 20;
// 		var elWidth = $(el).outerWidth();
//
// 		var arrowLeft = offsetLeft;
//
//
// 		console.log( offsetLeft );
// 		// console.log( offsetTop, elHeight,  offsetTop + elHeight);
// 		$('.employee-info').css({
// 			top: offsetTop + elHeight
// 		});
// 		$('.employee-info .arrow').css({
// 			left: arrowLeft + elWidth/2
// 		});
//
// 	}
// };



App.EmployeesNew = {

	current:null,

	init(){
		var _this = this;
		if( $(".employees__institution-chose .button").length === 0 ) return;

		$(".employees__institution-chose").on('click','.button',this.onClick.bind(this));
		


	},

	onClick(e){
		e.preventDefault();

		var el = e.currentTarget;
		this.current = el;

		var open = !$(el).hasClass('active');
		if( $(el).hasClass('active') ){
			$(".button", $(el).parent()).removeClass('active');
		}else{
			$(".button", $(el).parent()).removeClass('active');
			$(el).addClass('active');
		}

		// this.setPosition();

		this.toggleDetails(open);



	},
	toggleDetails(open){
		var el = this.current;
		var targetId = $(el).data('target');
		var $target = $('#'+targetId);
		console.log(targetId, open);

		if( open ){
			$target.slideDown('fast',function(){
				$(this).addClass('active');
			});
			$target.siblings('.employees').hide().removeClass('active');
		}
		else{
			$($target).slideUp('fast',function(){
				$(this).removeClass('active');
			});
		}


	},
	
};

App.PhotoSlider = {
	init(){
		$('#photo-slider').slick({
			infinite: true,
			// adaptiveHeight: true,
			dots: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1
		});

		// On after slide change
		$('#photo-slider').on('afterChange', this.afterChange);


	},
	afterChange(e, slick, currentSlide){
		var index = slick.currentSlide;

		$('.photo-slider .photo').eq(index)
		.addClass('active')
		.siblings().removeClass('active');

		console.log(index);
	}

};


App.GalleryYearSelect = {
	init(){
		$('#years-dropdown').on('click','a',this.onClick.bind(this));
		$('.month-select .months').on('click','a',this.onClickMonth.bind(this));

	},
	getYear(){
		var $e = $('#years-dropdown .active');
		var year = $e.text();
		return year;
	},
	showAvailableMonths(){
		var year = this.getYear();
		$(".month-select .months a").each(function(i,el) {
			var month = parseInt( $(el).text() );
			var foundOne = $(".gallery-container .gallery-item[data-month="+month+"][data-year="+year+"]").length;
			if(foundOne){
				$(el).css({width:'',border:''});
			}
			else{
				$(el).css({width:0,border:0});
			}
		});
	},
	onClick(e){
		e.preventDefault();
		App.log(e);
		var year = $(e.target).text();
		$('.year-select').text(year);
		$('#years-dropdown a').removeClass('active');
		$(e.target).addClass('active');


		// var link = window.location.href;
		// link = link.replace(/year=([0-9]+)/i,'') + '?year='+year;
		App.Gallery.filter();

		$('#years-dropdown').foundation('close');
	},
	onClickMonth(e){
		e.preventDefault();
		console.log(e);
		var month = $(e.target).text();

		// App.Gallery.destroy();

		// App.Gallery.init();

		if( $(e.target).hasClass('active') === false ){
			App.Gallery.filter('[data-month='+month+']');
			$(e.target).addClass('active').siblings().removeClass('active');
			// $('.gallery-container').slick('slickFilter','[data-month='+month+']');
		}
		else{
			App.Gallery.filter(null);
			$(e.target).removeClass('active');
			// $('.gallery-container').slick('slickUnfilter');

		}



	}
};



App.Gallery = {

	year:null,
	itemsCache:null,
	itemsFiltered:null,

	init(){
		if( $(".gallery-container").length === 0 ) return;
		// this.destroy();

		var _this = this;
		if( $(".gallery-item").length === 0 ) return;

		$(".gallery-slider").on('click',function (e) {
			e.stopPropagation();
		});

		$(".pagination").on('click','li',function (e) {
			e.preventDefault();

			_this.page( parseInt(this.textContent) );
		});
		$(".gallery-container").on('click','.expand-btn',this.onClick.bind(this));
		$(".gallery-container").on('click','.download-btn',this.onClickDownload.bind(this));
		$(".gallery-container").on('click','.gallery-item',this.onClick.bind(this));

		this.itemsCache = $(".gallery-item");
		this.itemsFiltered = $(".gallery-item");


		this.createPagination();
		this.page(1);


	},
	destroy(){
		if( $(".gallery-container")[0]['slick'] &&  $(".gallery-container")[0]['slick'].unslicked === false ){
			$('.gallery-container').slick('unslick');
		}
		$(".gallery-container").unbind('click');
		$(".gallery-slider").unbind('click');
		$(".pagination").unbind('click');

	},
	filter(filter){
		filter = filter || '';
		var f = filter + '[data-year='+ App.GalleryYearSelect.getYear() +']';
		console.log(f);
		// this.destroy();
		$(".gallery-container .gallery-item").remove();
		var toAppend = this.itemsCache.clone().removeClass('filter').hide().filter(f).addClass('filter').show();
		this.itemsFiltered = toAppend;
		$(".gallery-container").append(toAppend);


		this.createPagination();
		this.page(1);
	},

	createPagination(){
		$('.pagination').empty();
		var l = this.itemsFiltered.length;
		var pages = Math.ceil(l/8);
		for (var i = 0; i < pages; i++) {
			var li = document.createElement('li');
			li.textContent = i + 1;
			if(i===0) li.className = 'active';
			$('.pagination').append(li);
		}

	},
	page(nr){
		console.log(nr);
		nr = nr - 1;
		var from = nr * 8;
		var to = from + 7;
		var ganim = 1;
		this.itemsFiltered.hide().removeClass('g-1 g-2 g-3 g-4 g-5 g-6 g-7 g-8').each(function (i,el) {
			if( i >= from && i <= to ){
				let j = ganim;
				setTimeout(function(){
					$(el).show().addClass('g-'+j);
				},10);
				ganim++;
			}

		});
		$('.pagination li').removeClass('active').eq(nr).addClass('active');
		App.GalleryYearSelect.showAvailableMonths();
	}
	,
	load(url){
		var _this = this;
		$.get( url ).then(function(data) {
			// console.log(data);
			// var c = $('.pagination',data).children();
			var g = $('.gallery-container',data).children();
			// $('.pagination').html(c);
			$('.gallery-container').html(g);
			_this.destroy();
			_this.init();
		},'html');
	},
	onClick(e){
		e.preventDefault();
		e.stopPropagation();

		var galleryItem = $(e.currentTarget).closest('.gallery-item');

		var a = $(galleryItem).find('.expand-btn');
		var el = galleryItem[0];
		var href = $(a).attr('href');
		var id = $(a).data('id');
		var images = $(a).data('images');
		App.Gallery.Slider.current = el;

		var open = !$(el).hasClass('active');
		if( $(el).hasClass('active') ){
			$(".gallery-item").removeClass('active');
		}else{
			$(".gallery-item").removeClass('active');
			$(el).addClass('active');
		}

		// set download url
		var $dBtn = $('.download-btn',galleryItem);
		if( $dBtn.length ){
			$('.slider-download').show().attr('href', $dBtn.attr('href') );
		}else{
			$('.slider-download').hide();
		}

		// console.log(el, id);

		App.Gallery.Slider.setPosition();
		App.Gallery.Slider.toggleGallerySlider(open);
		App.plugins.ScrollTo.element(el);

		if(open){
			App.Gallery.Slider.init(images);
		}



	},
	onClickDownload(e){
		e.stopPropagation();
	}


};


App.Gallery.Slider = {
	current:null,
	data:null,
	init(images){
		var _this = this;
		// console.log(images);


		if( $('.gallery-slick-slider')[0]['slick'] ){
			$('.gallery-slick-slider').slick('unslick');
			$('.gallery-slider .close').unbind('click');
			$([document.documentElement,window]).unbind('click');
		}

		var imagesHtml = images.map(function(el){
			var div = document.createElement('div');
			var img = document.createElement('img');
			div.appendChild(img);
			img.src = el;
			return div;
		});

		$('.gallery-slick-slider').html(imagesHtml);

		$('.gallery-slick-slider').on('afterChange', this.afterChange);
		$('.gallery-slick-slider').on('init', this.afterChange);

		$('.gallery-slick-slider').slick({
			speed: 222,
			infinite: true,
			adaptiveHeight: false,
			dots: false,
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: '.gallery-slider .arrow-prev',
			nextArrow: '.gallery-slider .arrow-next'
		});

		$(document.documentElement).on('click',this.onBodyClick.bind(this));
		$('.gallery-slider .close').on('click',this.onCloseClick.bind(this));
		$(window).resize(function () {
			_this.setPosition();
		});

	},
	afterChange(e, slick){
		var index = slick.currentSlide;
		var count = slick.slideCount;
		var text = (index+1) + '/' + count;

		$('.gallery-slider .pager').text( text );
		// $('.photo-slider .photo').eq(index)
		// .addClass('active')
		// .siblings().removeClass('active');

		console.log(index);
	},
	toggleGallerySlider(open){
		var el = this.current;

		if( open ){
			$('.gallery-slider').slideDown('fast',function(){
				$(this).addClass('active');
			});


		}
		else{
			$(".gallery-item").removeClass('active');
			$('.gallery-slider').slideUp('fast',function(){
				$(this).removeClass('active');
			});
		}


	},
	setPosition(){
		var el = this.current;
		if(!el) return;
		var offsetTop = $(el).position().top;
		// var offsetLeft = $(el).position().left;
		var offsetLeft = $(el).offset().left;
		var elHeight = $(el).outerHeight() + 10;
		var elWidth = $(el).outerWidth();

		var arrowLeft = offsetLeft;


		App.log( offsetLeft );
		// console.log( offsetTop, elHeight,  offsetTop + elHeight);
		$('.gallery-slider').css({
			top: offsetTop + elHeight
		});
		$('.gallery-slider .arrow').css({
			left: arrowLeft + elWidth/2
		});

	},
	onBodyClick(e){
		App.log(e);
		this.toggleGallerySlider(false);
	},
	onCloseClick(e){
		e.preventDefault();
		// App.log(e);
		this.toggleGallerySlider(false);
	}

};





App.SquareGallery = {

	current:null,
	$galleryWrapper:null,

	init(){
		var _this = this;
		if( $(".square-gallery-item").length === 0 ) return;

		$(".square-gallery-item").on('click',this.onClick.bind(this));
		$("#galleryMore").on('click',this.onGalleryMoreClick.bind(this));
		this.$galleryWrapper = $('#galleryWrapper');
		$(window).resize( this.onResize.bind(this) );

		$(".square-gallery-item_image").matchHeight();
		$('.square-gallery-container').masonry({})
		.on( 'layoutComplete', this.onResize.bind(this) );
		$(window).trigger('resize');

	},

	onClick(e){
		e.preventDefault();
		e.stopPropagation();

		var el = e.currentTarget;
		var images = $(el).data('images');
		var index = $(el).data('index');
		this.current = el;

		var open = !$(el).hasClass('active');
		if( $(el).hasClass('active') ){
			$(".square-gallery-item").removeClass('active');
		}else{
			$(".square-gallery-item").removeClass('active');
			$(el).addClass('active');
		}

		App.log(el);
		// var items = [
		//     {
		//         src: 'https://placekitten.com/600/400',
		//         w: 600,
		//         h: 400
		//     },
		//     {
		//         src: 'https://placekitten.com/1200/900',
		//         w: 1200,
		//         h: 900
		//     }
		// ];
		this.openPhotoswipe(e, images, index);


	},
	closedHeight(){
		var h = $('.square-gallery-item').height() * 2;
		return h;
	},
	expanded(){
		return  this.$galleryWrapper.height() >= $('.square-gallery-container').height();
	},
	onGalleryMoreClick(e){

		var expanded = this.expanded();

		if(!expanded){
			$("#galleryMore").addClass('active');
			this.$galleryWrapper.height( $('.square-gallery-container').height() );
		}
		else{
			$("#galleryMore").removeClass('active');
			this.$galleryWrapper.height( this.closedHeight() );

		}


	},
	openPhotoswipe(e, items, index){
		var pswpElement = document.querySelectorAll('.pswp')[0];

		// define options (if needed)
		var options = {
		    arrowKeys: true,
			preload:1,
		    index: index, // start at first slide
			tapToToggleControls: false,

			closeOnScroll: false,
			closeOnVerticalDrag: true,
			parseVerticalMargin: true,
			barsSize: {top:44, bottom: 44},

			closeEl:true,
			captionEl: false,
			fullscreenEl: false,
			zoomEl: false,
			shareEl: false,
			counterEl: true,
			arrowEl: true,
			preloaderEl: true
		};

		// Initializes and opens PhotoSwipe
		var gallery = new PhotoSwipe( pswpElement, PhotoSwipeUI_Default, items, options);
		gallery.init();
	},
	onResize(e){
		var h = $('.square-gallery-item').height() * 2;
		this.$galleryWrapper.height(h);

	}



};


App.plugins = App.plugins || {};
App.plugins.ScrollTo = {
	element(el){
		var s = $(el).offset().top;
		this._scroll(s);

	},
	pos(y){
		this._scroll(y);
	},
	_scroll(s){
		$('body,html').animate({
			scrollTop: s
		},333);
	}
};


App.Stellar = {
	init(){

		$.stellar({
		  // Set scrolling to be in either one or both directions
		  horizontalScrolling: false,
		  verticalScrolling: true,

		  // Set the global alignment offsets
		  horizontalOffset: 0,
		  verticalOffset: window.innerHeight/2, // 0,

		  // Refreshes parallax content on window load and resize
		  responsive: true,

		  // Select which property is used to calculate scroll.
		  // Choose 'scroll', 'position', 'margin' or 'transform',
		  // or write your own 'scrollProperty' plugin.
		  scrollProperty: 'scroll',

		  // Select which property is used to position elements.
		  // Choose between 'position' or 'transform',
		  // or write your own 'positionProperty' plugin.
		  positionProperty: 'position',
		//   positionProperty: 'transform',

		  // Enable or disable the two types of parallax
		//   parallaxBackgrounds: true,
		  parallaxElements: true,

		  // Hide parallax elements that move outside the viewport
		  hideDistantElements: false,

		  // Customise how elements are shown and hidden
		//   hideElement: function($elem) { $elem.hide(); },
		//   showElement: function($elem) { $elem.show(); }
		});
	}
};




if( !App.isMobile() ){
	App.Stellar.init();
}
App.SquareGallery.init();
App.GalleryYearSelect.init();
App.Gallery.init();



$(function() {
	$('.employee').matchHeight();
});
//
// $(document).ready(function() {
// 	 $(".lettering").lettering();
//
// });

// $('.kids-promo .text > *').addClass('hide');
$(function() {
	if( App.isMobile() ) return;

	var $div = $('.bg-white-icons:first');
	var $k = $('.kids-promo');

	$(window).scroll(function(e) {
		var scroll = window.scrollY;
		var size = parseInt( scroll / 8 );
		// console.log(scroll, size);

		$div.css({
			'box-shadow': 'inset rgba(0, 0, 0, 0.2) 0 0 '+ size +'px 0',
			// 'transform': 'scale('+ (1 - size/118) +')'
		});

		// $k.css({
		// 	'transform': 'scale('+ (1 - size/500) +')',
		// 	'-webkit-filter': 'brightness('+ (1 + size/400) +')'
		// });

	});

});



App.MessageAlert.init();
App.PhotoSlider.init();
// init offer slider
App.SlickOfferSlider.init();
App.MobileMenu.init();
// App.Employees.init();
App.EmployeesNew.init();

function initMap() {
	App.Maps.init();
}
